import React, { useCallback, useEffect, useMemo, useState } from 'react'
import DeliveryBoxChoice from './DeliveryBoxChoice'
import Delivery from '../../icons/Delivery'
import BranchComponent from '../../icons/House'
import { useDispatch, useSelector } from 'react-redux'
import { changeDeliveryType } from '@/redux/actions/orderAction'
import { webalizeBranchName } from '@/functions/common/helper'
import Spinner from '../shared/Spinner'
import { WarePriceTypeEnum } from '@/constants/constants'
import { changeDeliveryData } from '@/functions/orderControl/orderControl'
import { performScroll } from '@/functions/ui/scroller'
import { orderDeliveryTypeSelector, userLoggedInSelector } from '@/selectors/selectors'
import AddressAutocompleteInput from '@/ui/components/forms/AddressAutocompleteInput'
import { showError, showInfo } from '@/functions/ui/toastify'
import { useRouter } from 'next/router'
import { userAddressesHook } from '@/functions/user/userUtil'
import SavedUserAddress from '@/ui/components/user/address/SavedUserAddress'

const DeliveryBox = () => {
    const router = useRouter()
    const orderDeliveryType = useSelector(orderDeliveryTypeSelector)

    const [finalAddress, setFinalAddress] = useState(null)
    const [clicked, setClicked] = useState(false)
    const dispatch = useDispatch()
    const userLoggedIn = useSelector(userLoggedInSelector)
    const { userAddresses, fetchUserAddresses, validateAddressOnBranch } = userAddressesHook()

    const handleChoiceClick = useCallback(
        deliveryType => {
            dispatch(changeDeliveryType(deliveryType))
            if (deliveryType === WarePriceTypeEnum.INHOUSE) {
                performScroll('regions')
                setFinalAddress(null)
            }
        },
        [dispatch]
    )

    useEffect(() => {
        fetchUserAddresses()
    }, [userLoggedIn])

    const redirectToBranch = () => {
        if (buttonEnabled) {
            if (finalAddress !== null && !finalAddress.error) {
                setClicked(true)
                changeDeliveryData(dispatch, finalAddress)

                const { resolveLocation } = finalAddress

                router.push(webalizeBranchName(resolveLocation.companyBranch.address.location.city))
            } else if (orderDeliveryType.text === WarePriceTypeEnum.INHOUSE) {
                performScroll('regions')
            } else {
                if (finalAddress && finalAddress.error) showError(finalAddress.message)
                else showInfo('Prosím upřesněte adresu')
            }
        } else showInfo('Prosím nejdříve vyplňte platnou adresu')
    }

    const buttonEnabled = useMemo(() => {
        if (orderDeliveryType.text === WarePriceTypeEnum.INHOUSE) return true
        else return finalAddress !== null && !finalAddress.error
    }, [orderDeliveryType, finalAddress])

    const renderUserAddresses = useCallback(() => {
        if (userAddresses)
            return userAddresses.map(value => {
                let match = false
                if (finalAddress) {
                    if (finalAddress.resolveLocation) {
                        if (
                            finalAddress.resolveLocation.gpsCoordinates.latitude === value.gps.latitude &&
                            finalAddress.resolveLocation.gpsCoordinates.longitude === value.gps.longitude
                        )
                            match = true
                    }
                }
                return (
                    <SavedUserAddress key={Math.random()} item={value} selected={match} onClick={changeToUserAddress} />
                )
            })
    }, [userAddresses, finalAddress])

    const changeToUserAddress = useCallback(item => {
        validateAddressOnBranch(item, null, null, setFinalAddress)
    }, [])

    return (
        <div className={'relative w-full h-fit'}>
            <div className={'mx-auto container px-2 xs:px-6 xl:px-0  max-w-2xl  -mt-20 xl:-mt-28 '}>
                <div className={'bg-turbo-delivery rounded-xl  pt-6 pb-6 xs:pb-3 xl:px-12 xl:pt-0 xl:pb-12'}>
                    <div className={'text-white flex justify-center  fk-screamer-title xl:pb-9'}>
                        Turbo pizza na váš stůl
                    </div>
                    <div className={'ml-4 mr-4 mt-4 xl:m-0 '}>
                        <div className={'xs:flex block'}>
                            <DeliveryBoxChoice
                                title={'Chci dovézt'}
                                icon={<Delivery />}
                                left={true}
                                changeDeliveryType={handleChoiceClick}
                            />
                            <DeliveryBoxChoice
                                title={'Vyzvednu si'}
                                icon={<BranchComponent />}
                                left={false}
                                changeDeliveryType={handleChoiceClick}
                            />
                        </div>
                        {orderDeliveryType.text === WarePriceTypeEnum.DELIVERY ? (
                            <AddressAutocompleteInput finishAction={setFinalAddress} />
                        ) : (
                            <div className={' h-0.5'}></div>
                        )}
                        {orderDeliveryType.text === WarePriceTypeEnum.DELIVERY &&
                            userAddresses &&
                            userAddresses.length > 0 && (
                                <div className={'flex flex-col gap-4 items-center justify-center bg-white pt-4'}>
                                    <span className={'font-fk_screamer text-3xl'}>Vaše uložené adresy</span>
                                    <div className={'flex flex-col  w-full bg-white pt-4 px-8'}>
                                        {renderUserAddresses()}
                                    </div>
                                </div>
                            )}
                        <div className={'bg-white p-4 rounded-b-xl flex justify-center '}>
                            <div
                                onClick={redirectToBranch}
                                className={`relative ${
                                    !buttonEnabled
                                        ? 'bg-turbo-gray text-white cursor-default'
                                        : 'text-turbo-primary hover:text-white cursor-pointer'
                                } button-background-solid  rounded-xl pb-3 pt-0.5 flex items-center justify-center  w-full lg:w-1/2`}
                            >
                                {clicked ? (
                                    <div className={'h-10 flex items-center'}>
                                        <Spinner color={'rgb(229, 231, 235)'} backgroundColor={'#E30A18'} />
                                    </div>
                                ) : (
                                    <div className={'fk-screamer-title xl:text-4xl '}>
                                        {orderDeliveryType.text === WarePriceTypeEnum.INHOUSE
                                            ? 'Vybrat pobočku'
                                            : 'Vybrat jídlo'}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default React.memo(DeliveryBox)
